const getTwoDigitNumber = (n: number | string) => {
	const s = n.toString()
	return s.length === 1 ? `0${s}` : s
}

type DateObj = {
	date: string
	month: string
	year: string
}

export const getDateObject = (date: string): DateObj => {
	const d = new Date(date)
	return {
		date: getTwoDigitNumber(d.getDate()).toString(),
		month: getTwoDigitNumber(d.getMonth() + 1).toString(),
		year: d.getFullYear().toString(),
	}
}

export const formatDate = (date: string) => {
	const d = new Date(date)
	return d.toLocaleDateString('en-us', {
		month: 'long',
		day: 'numeric',
		year: 'numeric',
	})
	// return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
}
