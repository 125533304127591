import { getDateObject } from './date.ts'

export const getPostUrl = (date: string, slug: string) => {
	const d = getDateObject(date)
	const url = `/${d.year}/${d.month}/${d.date}/${slug}`
	return url
}

export const getFullUrl = (date: string, slug: string) => {
	// getPostUrl(date, slug))
	return slug
	// return getPostUrl(date, slug)
	// return new Request(getPostUrl(date, slug)).url
}

export const getPostExcerpt = (excerpt: string) => {
	return replaceImage(excerpt).split(
		/<p class="link-more">|<div class="sharedaddy sd-sharing-enabled">/,
	)[0]
}

export const getPostExcerptElement = (excerpt: string) => {
	const extractedExcerpt = getPostExcerpt(excerpt)
	// const text = parse.htmlToDOM(extractedExcerpt).toString()

	return extractedExcerpt
}

export const replaceImage = (html: string) => {
	return html.replace(
		/https:\/\/media\.whiletheyweresleeping\.com\/|https:\/\/i[0-9]+\.wp\.com\/media\.whiletheyweresleeping\.com\//gi,
		'https://whiletheyweresleeping.mo.cloudinary.net/',
	)
}

export function getDomainUrl(request: Request) {
	const host =
		request.headers.get('X-Forwarded-Host') ?? request.headers.get('host')
	if (!host) {
		throw new Error('Could not determine domain URL.')
	}
	const protocol = host.includes('localhost') ? 'http' : 'https'
	return `${protocol}://${host}`
}

export function getErrorMessage(error: unknown) {
	if (typeof error === 'string') return error
	if (error instanceof Error) return error.message
	return 'Unknown Error'
}
