import type { Post } from '~/gql/queries.ts'
import { Link } from '@remix-run/react'
// import parse from 'html-react-parser'
import // FacebookShareButton,
// TwitterShareButton,
// EmailShareButton,
'react-share'
import {
	// getFullUrl,
	getPostExcerptElement,
	getPostUrl,
	replaceImage,
} from '~/utils/index.ts'
import { formatDate } from '~/utils/date.ts'

export default function Post({
	post,
	isExcerpted = false,
}: {
	post: Post
	isExcerpted?: boolean
}) {
	const postUrl = getPostUrl(post.date, post.slug)
	// const fullUrl = getFullUrl(post.date, post.slug)
	const body = isExcerpted
		? getPostExcerptElement(post.excerpt)
		: post.content
		? replaceImage(post.content)
				.split(`<div class="sharedaddy sd-sharing-enabled">`)[0]
				.toString()
		: ''

	return (
		<>
			<article className="post w-full pb-8">
				<header className="mb-4 text-center">
					<Link
						to={isExcerpted ? postUrl : '.'}
						className="title text-2xl text-theme-brown"
					>
						<h2 className="font-header text-sm font-light tracking-wider text-theme-yellow">
							{formatDate(post.date)}
						</h2>
						<h1 className="mb-4 mt-4 font-normal tracking-wider text-theme-brown">
							{post.title}
						</h1>

						{post.featuredImage && (
							<div className="relative sm:mx-4 md:mx-14 lg:mx-20 ">
								<img
									className="images w-full rounded-sm"
									width={300}
									height={
										300 *
										(post.featuredImage.node.mediaDetails.height /
											post.featuredImage.node.mediaDetails.width)
									}
									src={replaceImage(post.featuredImage.node.mediaItemUrl)}
									alt={post.featuredImage.node.altText}
								/>
							</div>
						)}
					</Link>
				</header>

				<div
					className="font-header flex flex-col space-y-3 text-sm font-normal leading-7 tracking-wider text-gray-900 "
					dangerouslySetInnerHTML={{ __html: body }}
				></div>
				{isExcerpted && (
					<div className="mt-4 flex flex-col items-center">
						<Link
							to={postUrl}
							className="more mx-auto inline-block border-2 border-theme-yellow px-4 py-2 text-sm font-light uppercase tracking-widest text-theme-yellow hover:text-theme-brown"
						>
							<div className="block w-full text-center">Read More »</div>
						</Link>
					</div>
				)}

				<div className="my-4 flex flex-row justify-center space-x-1 border-b-2 border-theme-yellow py-4 text-xs font-light uppercase tracking-wide ">
					<div>Share:</div>
					<ul className="article-social flex flex-row space-x-2 text-theme-yellow">
						{/* <li className="hover:text-theme-brown group">
							<FacebookShareButton
								url={fullUrl}
								className="inline-block text-xs font-light uppercase tracking-wide "
							>
								Facebook
							</FacebookShareButton>
						</li>
						<li className="hover:text-theme-brown group">
							<TwitterShareButton
								url={fullUrl}
								className="inline-block text-xs font-light uppercase tracking-wide"
							>
								Twitter
							</TwitterShareButton>
						</li> */}
						{/* <li className="hover:text-theme-brown group">
              <PinterestShareButton
                media={replaceImage(post.featuredImage.node.mediaItemUrl)}
                description={post.excerpt
                  .split('<div class="sharedaddy sd-sharing-enabled">')[0]
                  .replace(/(<([^>]+)>)/gi, "")}
                url={fullUrl}
                className="inline-block text-xs font-light tracking-wide uppercase"
              >
                Pintrist
              </PinterestShareButton>
            </li> */}

						<li className="group hover:text-theme-brown">
							{/* <EmailShareButton
								subject={`Checkout this post from While They Were Sleeping`}
								body={`I just read this post and thought you'd appreciate it.  ${post.title}`}
								separator=" :: "
								url={fullUrl}
								onClick={() => {}}
								openShareDialogOnClick
								className="inline-block text-xs font-light uppercase tracking-wide"
							>
								Email
							</EmailShareButton> */}
						</li>
					</ul>
				</div>

				<div className="flex flex-col items-center justify-center space-y-1 text-xs font-light uppercase tracking-wide sm:flex-row sm:justify-between sm:space-y-0">
					<div className="text-theme-yellow" id="comments">
						<Link
							to={isExcerpted ? `${postUrl}#comments` : '#comments'}
							className="nav "
						>
							{post.commentCount ? post.commentCount : 'No'} Comment
							{(Number(post.commentCount) > 1 || !post.commentCount) && 's'}
						</Link>
					</div>
					<div className="flex flex-col items-center justify-center space-y-1 sm:flex-row sm:items-center sm:space-x-2 sm:space-y-0">
						<div>
							Posted by{' '}
							<span className="text-theme-yellow">{post.author.node.name}</span>
						</div>
						<div>
							At{' '}
							<span className="text-theme-yellow ">
								{new Date(`${post.date}`).toLocaleTimeString('en-US', {
									hour: '2-digit',
									minute: '2-digit',
								})}
							</span>
						</div>
					</div>
				</div>
			</article>
		</>
	)
}
